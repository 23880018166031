import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import { Title, Subtitle, Input } from '../components/q'

export default function QText(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const inputName = props.i.inputName;
    const inputAutocomplete = props.i.inputAutocomplete;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const [isNew, setIsNew] = useState(true);
    const inputRef = useRef(null);

    useEffect(() => {
        if(q > 1 || a["q"+q] !== undefined) {
            inputRef.current.focus();
        }
    }, [])

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const isValid = value.replace(/\s/g, '').length > 0
        onAnswer({["q"+q]: {[dataKey]: value, "isValid": isValid}})
        if(isNew) {
            setIsNew(false);
        }
    }

    return(
        <>
            <FontAwesomeIcon
                icon={icon}
                style={{"color": color, fontSize: '30px'}}
            />
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Input
                ref={inputRef}
                type={"text"}
                defaultValue={a["q"+q]?.[dataKey]}
                onChange={onChange}
                $isValid={isNew ? true : a["q"+q]?.isValid}
                name={inputName}
                autoComplete={inputAutocomplete}
            />
        </>
    )
}