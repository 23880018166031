import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import { colors } from '../../../components/colors'

/* Components */
import {Title, Subtitle, Boxes, Box, IconTop, IconBtns, OptionTitle, Input} from '../components/q'
import {FontHeader48, FontSubtitle20} from "../../../components/fonts";

export default function QCheck(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const options = props.i.options;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const slim = props.i.slim;
    const allowOther = props.i.allowOther;
    const otherPlaceholder = props.i.otherPlaceholder
    const [isSelected, setIsSelected] = useState([]);
    const [openText, setOpenText] = useState(false)
    const inputRef = useRef(null);
    const [otherText, setOtherText] = useState("")
    const [isNew, setIsNew] = useState(true);
    const [isValid, setIsValid] = useState(false)



    useEffect(() => {
        if(isSelected === null) {
            setIsSelected(a["q"+q]?.[dataKey] === undefined ? null : a["q"+q]?.[dataKey].split(", "))
        }
    }, [])

    const onSelect = (title) => {
        if (title === "Other, please describe") {
            if (openText) {
                setOpenText(false)
                setIsSelected(isSelected.filter(f => f !== otherText))
            } else {
                setOpenText(true)
            }
        }
        else {
            if (isSelected === null) {
                    setIsSelected([title])

            } else if (isSelected.includes(title)) {
                setIsSelected(isSelected.filter(f => {
                    return (f !== title)
                }))
            } else {
                    setIsSelected([...isSelected, title])


            }
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const isValid = value.replace(/\s/g, '').length > 0
        // onAnswer({["q" + q]: {[dataKey]: [...isSelected, `Other, please describe: ${value}`], "isValid": isValid}})
        setOtherText(value)
        setIsValid(true)
        if(isNew) {
            setIsNew(false);
        }
    }

    const setSelect = () => {
        setIsSelected([...isSelected, `${otherText}`])
    }


    useEffect(() => {
        if(isSelected !== null) {
            onAnswer({["q"+q]: {[dataKey]: isSelected.join(", "), "isValid": isSelected.length > 0 || isValid}})
        }
    }, [isSelected, isValid])

    return(
        <>
            {/*{icon !== null &&*/}
            {/*    <IconTop>*/}
            {/*        <FontAwesomeIcon*/}
            {/*            icon={icon}*/}
            {/*            style={{"color": color, fontSize: '30px'}}*/}
            {/*        />*/}
            {/*    </IconTop>*/}
            {/*}*/}
            <FontSubtitle20 style={{
                marginBottom: '20px',
            }} $spaced={true}>{subtitle}</FontSubtitle20>
            <FontHeader48
            >{title}</FontHeader48>
            <Subtitle></Subtitle>
            <Boxes>
                {options.map((o, oIndex) => {
                    if (o.title !== 'Other, please describe') {
                        return <Box
                            key={oIndex}
                            onClick={() => {
                                onSelect(o.title)
                            }}
                            $selected={isSelected === null ? false : isSelected.includes(o.title)}
                            $color={color}
                            $slim={slim}
                        >
                            {o.icon !== null &&
                                <IconBtns>
                                    <FontAwesomeIcon
                                        icon={o.icon}
                                        style={{
                                            "color": (isSelected === o.title ? colors.white : color),
                                            fontSize: '30px'
                                        }}
                                    />
                                </IconBtns>
                            }
                            <OptionTitle>{o.title}</OptionTitle>
                        </Box>
                    }
                })}
            </Boxes>

            <br />
            {!openText && allowOther &&
                <OptionTitle onClick={() => setOpenText(true)} style={{cursor : 'pointer'}}>{'Other, please describe'}</OptionTitle>
            }
            {openText && <Input
                ref={inputRef}
                type={"text"}
                defaultValue={""}
                onChange={onChange}
                onBlur={setSelect}
                $isValid={isNew ? true : a["q"+q]?.isValid}
                name={otherText}
                autoComplete={"other"}
                placeholder={otherPlaceholder}

                />}
        </>
    )
}