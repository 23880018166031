import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import { colors } from '../../../components/colors'

/* Components */
import { Title, Subtitle, Boxes, Box, IconTop, IconBtns, OptionTitle } from '../components/q'

export default function QCheck(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const options = props.i.options;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const slim = props.i.slim;
    const [isSelected, setIsSelected] = useState(null);

    useEffect(() => {
        if(isSelected === null) {
            setIsSelected(a["q"+q]?.[dataKey] === undefined ? null : a["q"+q]?.[dataKey].split(", "))
        }
    }, [])

    const onSelect = (title) => {
        if(isSelected === null) {
            setIsSelected([title])
        } else if(isSelected.includes(title)) {
            setIsSelected(isSelected.filter(f => {return(f !== title)}))
        } else {
            setIsSelected([...isSelected, title])
        }
    }

    useEffect(() => {
        if(isSelected !== null) {
            onAnswer({["q"+q]: {[dataKey]: isSelected.join(", "), "isValid": isSelected.length > 0}})
        }
    }, [isSelected])

    return(
        <>
            {icon !== null &&
                <IconTop>
                    <FontAwesomeIcon
                        icon={icon}
                        style={{"color": color, fontSize: '30px'}}
                    />
                </IconTop>
            }
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Boxes>
                {options.map((o, oIndex) => (
                    <Box
                        key={oIndex}
                        onClick={() => {onSelect(o.title)}}
                        $selected={isSelected === null ? false : isSelected.includes(o.title)}
                        $color={color}
                        $slim={slim}
                    >
                        {o.icon !== null &&
                            <IconBtns>
                                <FontAwesomeIcon
                                    icon={o.icon}
                                    style={{"color": (isSelected === o.title ? colors.white : color), fontSize: '30px'}}
                                />
                            </IconBtns>
                        }
                        <OptionTitle>{o.title}</OptionTitle>
                    </Box>
                ))}
            </Boxes>
        </>
    )
}