import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import zipState from "zip-state";

/* Components */
import { Title, Subtitle, Input } from '../components/q'

export default function QZip(props) {
    const q = props.q;
    const a = props.a;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const inputName = props.i.inputName;
    const inputAutocomplete = props.i.inputAutocomplete;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const [v, setV] = useState("");
    const [isNew, setIsNew] = useState(true);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    useEffect(() => {
        const initValue = a["q"+q]?.zip;
        if(!isNaN(initValue) && initValue.length <= 5) {
            setV(initValue)
            if(initValue.length === 5 && isNew) {
                setIsNew(false);
            }
        }
    }, [a])

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if(value === undefined) {
            onAnswer({["q"+q]: {"zip": undefined, [dataKey]: undefined, "isValid": false}})
            setV(e.target.value);
        } else if(!isNaN(value) && value.length <= 5) {
            const region = zipState(value.toString())
            const isValid = region !== null && value.length === 5
            onAnswer({["q"+q]: {"zip": value, [dataKey]: region, "isValid": isValid}})
            if(value.length === 5 && isNew) {
                setIsNew(false);
            }
            setV(e.target.value);
        }
    }

    return(
        <>
            <FontAwesomeIcon
                icon={icon}
                style={{"color": color, fontSize: '30px'}}
            />
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Input
                ref={inputRef}
                type={"text"}
                value={v}
                onChange={onChange}
                $isValid={isNew ? true : a["q"+q]?.isValid}
                name={inputName}
                autoComplete={inputAutocomplete}
            />
        </>
    )
}