import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from '../../../components/colors';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/button';

const ButtonContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 20px;
  @media screen and (max-width: 1088px) {
    left: 0;
    top: 15px;
  }
`;

export default function Back(props) {
    const q = props.q;
    const setQ = props.setQ;

    const onBack = () => {setQ(q - 1)}

    return (
        <ButtonContainer>
            {q > 1 &&
                <Button
                    isValid={true}
                    clickable={true}
                    onClick={onBack}
                    label={"Back"}
                    icon={faArrowLeft}
                    iconSide={"left"}
                    colorButtonValid={colors.white}
                    colorButtonInvalid={colors.white}
                    colorTextValid={colors.primary30}
                    colorTextInvalid={colors.primary30}
                />
            }
        </ButtonContainer>
    );
};