import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Components */
import { colors } from '../../../components/colors'

/* Components */
import { Title, Subtitle, Boxes, Box, IconTop, IconBtns, OptionTitle } from '../components/q'

export default function QRadio(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const options = props.i.options;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const slim = props.i.slim;
    const [isSelected, setIsSelected] = useState(null);

    useEffect(() => {
        if(isSelected === null) {
            setIsSelected(a["q"+q]?.[dataKey])
        }
    }, [])

    const onChange = (title) => {
        setIsSelected(isSelected === title ? null : title)
        onAnswer({["q"+q]: {[dataKey]: title, "isValid": (isSelected === title ? false : title !== null)}})
    }

    return(
        <>
            {icon !== null &&
                <IconTop>
                    <FontAwesomeIcon
                        icon={icon}
                        style={{"color": color, fontSize: '30px'}}
                    />
                </IconTop>
            }
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Boxes>
                {options.map((o, oIndex) => (
                    <Box
                        key={oIndex}
                        onClick={() => {onChange(o.title)}}
                        $selected={isSelected === o.title}
                        $color={color}
                        $slim={slim}
                    >
                        {o.icon !== null &&
                            <IconBtns>
                                <FontAwesomeIcon
                                    icon={o.icon}
                                    style={{"color": (isSelected === o.title ? colors.white : color), fontSize: '30px'}}
                                />
                            </IconBtns>
                        }
                        <OptionTitle>{o.title}</OptionTitle>
                    </Box>
                ))}
            </Boxes>
        </>
    )
}