import React, {useState} from 'react';

/* Containers */
import QText from './qtext';
import QZip from './qzip';
import QRadio from "./qradio";
import QCheck from "./qcheck";
import QEmail from './qemail';
import QAssets from "./qassets";
import QPhone from "./qphone";
import {FontSubtitle20} from "../../../components/fonts";

export default function Q(props) {
    const [allQuestions, setAllQuestions] = useState(props.allQuestions)
    const q = props.q;
    const a = props.a;
    const setA = props.setA;
    const color = props.color;
    const [isForked, setIsForked] = useState([])

    const onAnswer = (answer) => {
        setA({...a, ...answer})
    }



    const onFork = (title, q) => {
            // if (title === 'Divorced') {
            //     const iQ = props.allQuestions[q - 1].injectQuestionsDivorce
            //     setAllQuestions(props.allQuestions.splice(q - 1, iQ.length, iQ))
            //
            // }
            // else if (title === 'Widowed') {
            //     const iQ = props.injectQuestionsWidow
            //     setAllQuestions(props.allQuestions.splice(q, iQ.length, iQ))
            // } else {
            //     setAllQuestions(props.allQuestions)
            // }

    }

    const onOther = () => {}

    return (
        <>
            {allQuestions.map((i, iIndex) => {
                if(q === (iIndex+1)) {
                    if(i.type === "qText") {
                        return(<QText key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qZip") {
                        return(<QZip key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QRadio") {
                        return (<QRadio key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} onFork={onFork}/>)
                    } else if(i.type === "QCheck") {
                        return(<QCheck key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qEmail") {
                        return(<QEmail key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qAssets") {
                        return(<QAssets key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QPhone") {
                        return(<QPhone key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    }
                }
            })}
        </>
    );
};