import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Btn = styled.button`
    gap: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 16px 32px;
    &:focus-visible {
        outline: none;
    }
    ${props => props.$width === undefined && css`
        width: fit-content;
    `}
    ${props => props.$width !== undefined && css`
        width: ${props => props.$width};
        max-width: calc(${props => props.$width} - 64px);
    `}
    margin: 0 auto;
    border-style: solid;
    border-radius: 12px;
    ${props => props.$clickable && css`
        background-color: ${props => props.$colorButtonValid};
        border-color: ${props => props.$colorButtonValid};
        cursor: pointer;
        color: ${props => props.$colorTextValid};
    `}
    ${props => !props.$clickable && css`
        background-color: ${props => props.$colorButtonInvalid};
        border-color: ${props => props.$colorButtonInvalid};
        cursor: default;
        color: ${props => props.$colorTextInvalid};
    `}
`;

export default function Button(props) {
    const clickable = props.clickable;
    const onClick = props.onClick;
    const label = props.label;
    const icon = props.icon;
    const iconSide = props.iconSide;
    const isValid = props.isValid;
    const colorButtonValid = props.colorButtonValid;
    const colorButtonInvalid = props.colorButtonInvalid;
    const colorTextValid = props.colorTextValid;
    const colorTextInvalid = props.colorTextInvalid;
    const width = props.width;

    return (
        <Btn
            onClick={onClick}
            $clickable={clickable}
            $colorButtonValid={colorButtonValid}
            $colorButtonInvalid={colorButtonInvalid}
            $colorTextValid={colorTextValid}
            $colorTextInvalid={colorTextInvalid}
            $width={width}
        >
            {iconSide === "left" &&
                <FontAwesomeIcon
                    icon={icon}
                    style={{"color": isValid ? colorTextValid : colorTextInvalid, fontSize: '16px'}}
                />
            }
            {label}
            {iconSide === "right" &&
                <FontAwesomeIcon
                    icon={icon}
                    style={{"color": isValid ? colorTextValid : colorTextInvalid, fontSize: '16px'}}
                />
            }
        </Btn>
    );
};