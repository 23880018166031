import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../components/colors';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';

/* Containers */
import Progress from './containers/progress'
import Q from './containers/q'
import Next from './containers/next'
import Back from "./containers/back";

/* Components */
import Footer from "../../components/footer";

/* Questions */
import { allQuestions } from './containers/allquestions';

/* Middleware */
import { setRopt, setSt } from "../../utils/store";

import {images} from '../../components/images'

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  backdrop-filter: blur(25px);
`;
const Background = styled.div`
  min-height: 100%;
  background-image: url("${images.surveyBackground}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;
const Content = styled.div`
    position: relative;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    box-sizing: border-box;
    @media screen and (max-width: 1088px) {
      padding: 15px;
    }
     overflow-y: auto;
`;
const Box = styled.div`
    position: relative;
    border-radius: 12px;
    width: 100%;
    max-width: 1068px;
    margin: 0 auto;
    flex: 1 1 0;
    align-content: center;
  background:transparent;
  color: white !important;
  font-family: LoraMedium, sans-serif !important;
  @media screen and (max-width: 780px) {
  //  margin: 10px;
    width: 100%;
  }
`;
const Inner = styled.form`
    margin: 30px 0;
    height: calc(100% - 5px - 60px);
    display: flex;
    flex-direction: column;
  @media screen and (min-width: 780px) {
    justify-content: safe center;
    align-items: center;
  }

    padding:0;
  
`;

export default function Survey() {
    const color = colors.action100;
    const backgroundColor = colors.canvaGray; // colors.canvaGreen;
    const [q, setQ] = useState(1);
    const [a, setA] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async (ropt, st) => {
        await dispatch(setRopt({"ropt": ropt}));
        await dispatch(setSt({"st": st}));
        navigate("/matches")
    }

    useEffect(() => {
        console.log(a);
    }, [a])

    return (
        <Background $backgroundColor={backgroundColor}>
            <Page>
                <Progress q={q} qTotal={allQuestions.length} color={colors.green}/>
                <Content>
                    <Back q={q} setQ={setQ} />
                    <Box>
                        <Inner>
                            <Q q={q} a={a} setA={setA} allQuestions={allQuestions} color={color} />
                            <Next q={q} qTotal={allQuestions.length} setQ={setQ} a={a} color={color} onFinish={onFinish} />
                        </Inner>
                    </Box>
                </Content>
                <Footer />
            </Page>
        </Background>
    );
};