import {
    faAddressCard,
    faChildren,
    faHeart,
    faPaw,
    faStar,
    faMagnifyingGlassDollar,
    faSeedling,
    faBagShopping,
    faPersonBurst,
    faComments,
    faMap,
    faPerson,
    faHouse,
    faHeartBroken,
    faChampagneGlasses,
    faRibbon,
    faBuilding,
    faIndustry,
    faLightbulb,
    faUserGroup,
    faUserTie,
    faVideo,
    faHandshakeSimple,
    faChildReaching,
    faPersonDigging,
    faHome,
    faGraduationCap,
    faBed,
    faUmbrellaBeach,
    faTableList,
    faMoneyBill,
    faMobileScreenButton,
} from "@fortawesome/free-solid-svg-icons";

export const allQuestions = [
    {
        "type": "qText",
        "dataKey": "firstName",
        "icon": faAddressCard,
        "title": "Let's begin, what is your first name?",
        "inputName": "fname",
        "inputAutocomplete": "given-name"
    },
    {
        "type": "qZip",
        "dataKey": "state",
        "icon": faMap,
        "title": "Where do you live?",
        "subtitle": "Zip code",
        "inputName": "zip",
        "inputAutocomplete": "postal-code"
    },
    {
        "type": "qEmail",
        "dataKey": "email",
        "icon": faComments,
        "title": "What's your email?",
        "subtitle": "To receive your matches",
        "inputName": "email",
        "inputAutocomplete": "email"
    },
    {
        "type": "QRadio",
        "dataKey": "maritalStatus",
        "icon": faHeart,
        "title": "What is your relationship status?",
        "subtitle": "Choose one",
        "options": [
            {"title": "Single", "icon": faPerson},
            {"title": "Relationship", "icon": faChampagneGlasses},
            {"title": "Married", "icon": faHeart},
            {"title": "Domestic Partnership", "icon": faHouse},
            {"title": "Divorced", "icon": faHeartBroken},
            {"title": "Widowed", "icon": faRibbon},
        ],
        "slim": false
    },
    // {
    //     "type": "qText",
    //     "dataKey": "maritalStatus",
    //     "icon": faHeart,
    //     "title": "If you are in the divorce process, please let me know at which stage - planning a divorce, going through a divorce, or post-divorce."
    // },
    // Fork to ask followup question on divorce
    {
        "type": "qText",
        "dataKey": "numChildren",
        "icon": faChildren,
        "title": "How many children do you have?",
        "subtitle": "or other dependents"
    },
    {
        "type": "qText",
        "dataKey": "pets",
        "icon": faPaw,
        "title": "Do you have animal babies?",
        "subtitle": "if yes, what kind?"
    },
    {
        "type": "qText",
        "dataKey": "interests",
        "icon": faStar,
        "title": "What are your interests, hobbies, & passions?"
    },
    {
        "type": "qText",
        "dataKey": "employed",
        "icon": faBagShopping,
        "title": "Are you currently employed?",
        "subtitle": "if yes, what is your profession?"
    },
    {
        "type": "QRadio",
        "dataKey": "ownBusiness",
        "icon": faBuilding,
        "title": "Do you own a business?",
        "subtitle": "Choose one",
        "options": [
            {"title": "Yes", "icon": null},
            {"title": "No", "icon": null}
        ],
        "slim": false
    },
    // {
    //     "type": "qText",
    //     "dataKey": "profession",
    //     "icon": faIndustry,
    //     "title": "What industry is your profession?"
    // },
    // Fork do you own your own business
    {
        "type": "QRadio",
        "dataKey": "workingWithFinAdvisor",
        "icon": faSeedling,
        "title": "Do you work with a financial advisor?",
        "subtitle": "Choose one",
        "options": [
            {"title": "Yes", "icon": null},
            {"title": "No", "icon": null}
        ],
        "slim": false
    },
    {
        "type": "qText",
        "dataKey": "whyLookingForFinAdvisor",
        "icon": faMagnifyingGlassDollar,
        "title": "Why are you looking for an advisor?"
    },
    {
        "type": "QCheck",
        "dataKey": "typeOfAdvisor",
        "icon": faPersonBurst,
        "title": "Describe your perfect advisor?",
        "subtitle": "choose any",
        "options": [
            {"title": "Introverted", "icon": faLightbulb},
            {"title": "Extroverted", "icon": faUserGroup},
            {"title": "All business", "icon": faUserTie},
            {"title": "Personable", "icon": faChildReaching},
            {"title": "Meets virtually", "icon": faVideo},
            {"title": "Meets locally", "icon": faHandshakeSimple},
        ],
        "slim": false
        // Add open ended option at the bottom
    },
    // "title": "If we could build your perfect advisor, especially for you, what would they be like? Are they introverted or extroverted, all business or incorporate more emotional aspects, virtual or in-person meetings, how do they charge for their services, something else?"
    {
        "type": "qAssets",
        "dataKey": "ropt",
        "icon": faMoneyBill,
        "title": "How much money is in your bank & investment accounts?",
        "min": 100000,
        "minLabel": "Under $100k",
        "max": 2000000, //5000000,
        "maxLabel": "Over $2m",
        "step": 50000, //100000
        "initValue": 500000
    },
    // if going through a divorce ask the assets question differently
    {
        "type": "QCheck",
        "dataKey": "currentFinancialGoals",
        "icon": faTableList,
        "title": "What are your financial goals?",
        "subtitle": "choose any",
        "options": [
            {"title": "Grow wealth", "icon": faSeedling},
            {"title": "Get comfortable", "icon": faBed},
            {"title": "Afford school", "icon": faGraduationCap},
            {"title": "Buy real estate", "icon": faHome},
            {"title": "Manage debt", "icon": faPersonDigging},
            {"title": "Retirement planning", "icon": faUmbrellaBeach},
        ],
        "slim": false
        // Add open ended option at the bottom
    },
    {
        "type": "QCheck",
        "dataKey": "referral",
        "icon": faComments,
        "title": "How did you hear about us?",
        "subtitle": "choose any",
        "options": [
            {"title": "Google", "icon": null},
            {"title": "Article", "icon": null},
            {"title": "TV", "icon": null},
            {"title": "Podcast", "icon": null},
            {"title": "Social Media", "icon": null},
            {"title": "Word of Mouth", "icon": null},
            {"title": "Other", "icon": null},
        ],
        "slim": true
    },
    {
        "type": "QPhone",
        "dataKey": "phone",
        "icon":faMobileScreenButton,
        "title":"Your matches are ready!",
        "subtitle":"A member of Willow’s Customer Success team will call you to review your matches and answer any questions. Your phone number will not be shared with advisors without your permission.",
        "inputName":"number",
        "inputAutocomplete": "000-000-0000"
    },
]
